import React from "react";
import MainTemplate from "../../templates/MainTemplate";
import SubpageHeader from "../../components/organisms/SubpageHeader";
import Accordion from "../../components/molecules/Accordion";
import styled from "styled-components";
import Headline from "../../components/atoms/Headline";
import Text from "../../components/atoms/Text";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../../components/atoms/Button";
import TheyWorkWithUs from "../../components/organisms/TheyWorkWithUs";
import WhySecureSection from "../../components/organisms/WhySecureSection";

const BeOurPartnerSection = styled.section`
  margin: 200px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const BigHeadline = styled(Headline)`
  text-transform: uppercase;
  text-align: center;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.size28};
  margin-bottom: 100px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size48};
  }
`;

const Content = styled.div`
  margin-bottom: 100px;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    column-count: 2;
    column-gap: 100px;
  }
  /* * {
    text-align: justify;
  } */
`;

const StyledText = styled(Text)`
  margin-bottom: 15px;

  p {
    margin-bottom: 15px;
  }
`;

const BeOurPartnerImage = styled.div`
  width: 100%;
`;

const WhyUsSection = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const WhyEstateSection = styled.section`
  margin: 100px 0;
  padding: 100px 15px;
  background-color: #000;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 100px 5%;
  }

  @media (min-width: 1200px) {
    padding: 100px 0;
  }
`;

const WhyEstateContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const WhyEstateContent = styled.div`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    column-count: 2;
    column-gap: 100px;
  }
`;

const EarnWithMe = () => {
  const imageData = useStaticQuery(query);
  return (
    <MainTemplate
      isMainPage={false}
      title="Zarabiaj na nieruchomościach inwestując z nami"
      metaDescription="Zostań naszym partnerem biznesowym i zarabiaj do 20% w skali roku! Wykreujemy dla Ciebie produkt inwestycyjny z którego uzyskasz zysk!"
    >
      <SubpageHeader headline="Zarabiaj na nieruchomościach inwestując z nami" />
      <Accordion data={imageData} />
      <BeOurPartnerSection>
        <BigHeadline data-aos="headline-fadeup">
          Zostań naszym partnerem biznesowym i zarabiaj do 20% w skali roku!
        </BigHeadline>
        <Content data-aos="headline-fadeup">
          <StyledText
            dangerouslySetInnerHTML={{
              __html: imageData.datoCmsEarnWithMe.firstSectionText,
            }}
          />
          {/* <StyledText>
            Wykreujemy dla Ciebie produkt inwestycyjny z którego uzyskasz zysk!{" "}
            <strong>
              Chcesz zostać partnerem inwestycyjnym? Zadzwoń lub zostaw swój
              numer telefonu
            </strong>
          </StyledText>
          <StyledText>
            Wspólne inwestycje są oparte na zaufaniu. Gwarancji pewności zysków
            przy kwocie zainwestowanego kapitału.
          </StyledText>
          <StyledText>
            Do każdego partnera biznesowego podchodzimy indywidualnie,
            gwarantując pewność i bezpieczeństwo.
          </StyledText>
          <StyledText>
            Preferujemy wiele możliwości inwestowania w nieruchomości oraz wiele
            form zabezpieczenia kapitału.
          </StyledText>
          <StyledText>
            <strong>
              Chcesz się dowiedzieć jakie? I zarabiać do 20% w skali roku?
            </strong>
          </StyledText> */}
        </Content>
        <BeOurPartnerImage>
          <GatsbyImage
            image={imageData.file.childImageSharp.gatsbyImageData}
            alt="Zarabiaj 20% w skali roku"
            data-aos="headline-fadeup"
          />
        </BeOurPartnerImage>
      </BeOurPartnerSection>
      <WhyUsSection id="whyus-trigger">
        <Headline
          data-aos="headline-fadeup"
          data-aos-anchor="#whyus-trigger"
          style={{ textAlign: "center", marginBottom: "50px" }}
        >
          {imageData.datoCmsEarnWithMe.secondSectionHeadline}
        </Headline>
        <Text
          style={{ marginBottom: "70px" }}
          data-aos="headline-fadeup"
          data-aos-delay="200"
          data-aos-anchor="#whyus-trigger"
          dangerouslySetInnerHTML={{
            __html: imageData.datoCmsEarnWithMe.secondSectionText,
          }}
        />

        <Button
          text="Kontakt"
          link="/kontakt"
          data-aos="headline-fadeup"
          data-aos-delay="400"
          data-aos-anchor="#whyus-trigger"
        />
      </WhyUsSection>
      <WhyEstateSection id="whyestate-trigger">
        <WhyEstateContainer>
          <Headline
            data-aos="headline-fadeup"
            data-aos-anchor="#whyestate-trigger"
            style={{ color: "#fff", marginBottom: "80px" }}
          >
            {imageData.datoCmsEarnWithMe.thirdSectionHeadline}
          </Headline>
          <WhyEstateContent>
            <Text
              style={{ color: "#fff" }}
              data-aos="headline-fadeup"
              data-aos-delay="200"
              data-aos-anchor="#whyestate-trigger"
              dangerouslySetInnerHTML={{
                __html: imageData.datoCmsEarnWithMe.thirdSectionText,
              }}
            />

            {/* <Text
              style={{ color: "#fff" }}
              data-aos="headline-fadeup"
              data-aos-delay="400"
              data-aos-anchor="#whyestate-trigger"
            >
              Przewagą nieruchomości nad innymi formami inwestycji jest to, że
              nieruchomość posiada charakter namacalnego aktywa, w którym można
              mieszkać, wynajmować lub uzyskać dochód ze sprzedaży.
            </Text> */}
          </WhyEstateContent>
        </WhyEstateContainer>
      </WhyEstateSection>
      <WhySecureSection />
      <TheyWorkWithUs />
    </MainTemplate>
  );
};

const query = graphql`
  query EarnMoreImageQuery {
    file(name: { eq: "zarabiaj-20" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE)
      }
    }
    datoCmsEarnWithMe {
      firstSectionText
      firstSectionHeadline
      secondSectionHeadline
      secondSectionText
      thirdSectionHeadline
      thirdSectionText
      firstAccordionLink
      firstAccordionText
      firstAccordionTitle
      secondAccordionLink
      secondAccordionTextLeft
      secondAccordionTextRight
      secondAccordionTitle
      thirdAccordionLink
      thirdAccordionTextAfter
      thirdAccordionTextBefore
      thirdAccordionTitle
    }
  }
`;

export default EarnWithMe;
