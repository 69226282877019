import React, { useState } from "react";
import styled from "styled-components";
import Text from "../atoms/Text";

const Wrapper = styled.section`
  margin: 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }
  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const AccordionWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid rgba(35, 42, 62, 0.19);
  margin-top: 30px;
`;

const TopHeadline = styled.h3`
  font-size: ${({ theme }) => theme.size24};
  font-weight: ${({ theme }) => theme.bold};
  color: #000000;
  transition: all 0.5s ${({ theme }) => theme.easing1};

  @media (min-width: ${({ theme }) => theme.tablet}) {
    font-size: ${({ theme }) => theme.size28};
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size32};
  }
`;

const TopTitle = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;

  &:hover ${TopHeadline} {
    color: ${({ theme }) => theme.primary};
  }
`;

const Content = styled.div`
  /* width: 100%; */
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ${({ theme }) => theme.easing1};

  /* @media (min-width: ${({ theme }) => theme.laptop}) {
    column-count: 2;
    column-gap: 100px;
  } */

  &.show {
    height: auto;
    max-height: 9999px;
    margin-top: 50px;
    margin-bottom: 50px;
    transition: all 0.5s ${({ theme }) => theme.easing1};
  }

  ul {
    padding-left: 30px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Arrow = styled.div`
  transform: rotate(180deg);
  transform-origin: center;
  transition: all 0.5s ${({ theme }) => theme.easing1};
  &.selected {
    transform: rotate(0);
    transition: all 0.5s ${({ theme }) => theme.easing1};
  }
`;

const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.size18};
  font-weight: ${({ theme }) => theme.light};
  margin-bottom: 20px;

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size18};
  }
  @media (min-width: ${({ theme }) => theme.desktop}) {
    font-size: ${({ theme }) => theme.size20};
  }
`;

const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
`;

const ContentText = styled.div`
  @media (min-width: ${({ theme }) => theme.laptop}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ContentTextLeft = styled.div`
  @media (min-width: ${({ theme }) => theme.laptop}) {
    width: 45%;
  }
`;

const ContentTextRight = styled.div`
  @media (min-width: ${({ theme }) => theme.laptop}) {
    width: 45%;
  }
`;

const Accordion = ({ data }) => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  return (
    <Wrapper>
      <AccordionWrapper>
        <TopTitle onClick={() => toggle(1)}>
          <TopHeadline>
            {data.datoCmsEarnWithMe.firstAccordionTitle}
          </TopHeadline>
          <Arrow className={selected == 1 && "selected"}>
            <svg
              width="33"
              height="19"
              viewBox="0 0 33 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.1253 18.9536L32.999 16.0798L16.9253 0.00608063L0.851563 16.0798L3.72535 18.9536L16.9253 5.75366L30.1253 18.9536Z"
                fill="black"
              />
            </svg>
          </Arrow>
        </TopTitle>
        <Content className={selected == 1 && "show"}>
          {/* <StyledText dangerouslySetInnerHTML={{ __html: data[0].text }} /> */}

          <StyledText
            dangerouslySetInnerHTML={{
              __html: data.datoCmsEarnWithMe.firstAccordionText,
            }}
          />

          {/* <StyledText>
            <strong>Co to jest mieszkaniowy produkt inwestycyjny ?</strong>
          </StyledText>
          <StyledText>
            <ul>
              <li>
                Jest to przearanżowane mieszkanie, które pozwala zarabiać na
                najmie znacznie więcej niż przy standardowym wynajmie
              </li>
              <li>
                Stworzony produkt inwestycyjny pozwala uzyskać nawet 2 razy
                większy zwrot niż przy standardowym najmie
              </li>
            </ul>
          </StyledText>
          <StyledText>
            <strong>Na tym polega nasza praca</strong>
          </StyledText>
          <StyledText>
            <ul>
              <li>
                Zajmujemy się całym procesem, od zakupu nieruchomości po
                przygotowanie do najmu.
              </li>
              <li>
                Inwestujemy tylko w mieszkaniowy rynek pierwotny i zabytkowe
                kamienice, bo interesują nas wyłącznie wyjątkowe produkty.
              </li>
            </ul>
          </StyledText> */}
          <IframeContainer>
            <iframe
              width="560"
              height="315"
              src={data.datoCmsEarnWithMe.firstAccordionLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </IframeContainer>
        </Content>
      </AccordionWrapper>
      <AccordionWrapper>
        <TopTitle onClick={() => toggle(2)}>
          <TopHeadline>
            {data.datoCmsEarnWithMe.secondAccordionTitle}
          </TopHeadline>
          <Arrow className={selected == 2 && "selected"}>
            <svg
              width="33"
              height="19"
              viewBox="0 0 33 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.1253 18.9536L32.999 16.0798L16.9253 0.00608063L0.851563 16.0798L3.72535 18.9536L16.9253 5.75366L30.1253 18.9536Z"
                fill="black"
              />
            </svg>
          </Arrow>
        </TopTitle>
        <Content className={selected == 2 && "show"}>
          <ContentText>
            <ContentTextLeft>
              <StyledText
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsEarnWithMe.secondAccordionTextLeft,
                }}
              />
              {/* <StyledText>
                <strong>Co jest najważniejsze przy wynajmie?</strong>
              </StyledText>
              <StyledText>
                Najważniejsze przy zarządzaniu najmem to jednoznaczna,
                przejrzysta umowa. Dodatkowo należy poinformować najemców o ich
                prawach i obowiązkach. Świadomość najemców o tym jak powinni
                użytkować przedmiot najmu pomaga nam uniknąć większości
                problemów związanych z najmem.
              </StyledText>
              <StyledText>
                Sam wybór najemcy nie może być przypadkowy i należy go dobrze
                zweryfikować.
              </StyledText>
              <StyledText>
                Większości kłopotów pozwala nam również uniknąć odpowiednio
                przygotowane mieszkanie.
              </StyledText> */}
            </ContentTextLeft>
            <ContentTextRight>
              <StyledText
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsEarnWithMe.secondAccordionTextRight,
                }}
              />
              {/* <StyledText>
                <strong>Jak przygotować mieszkanie pod najem?</strong>
              </StyledText>
              <StyledText>
                Inaczej przygotujemy mieszkanie pod najem długoterminowy, a
                inaczej pod najem krótkoterminowy.
              </StyledText>
              <StyledText>
                Sam lokal, lokalizacja, metraż, piętro i widok determinuje nam
                która forma najmu będzie dla nas bardziej opłacalna.
              </StyledText>
              <StyledText>
                Nasz zespół zajmuje się przygotowaniem mieszkań do najmu i
                znając te wszystkie okoliczności robi to bardzo świadomie:
              </StyledText>
              <StyledText>
                <ul>
                  <li>
                    Wyszukujemy mieszkania pod najem krótkoterminowy i zajmuje
                    się ich przygotowaniem
                  </li>
                  <li>
                    Wyszukujemy mieszkania pod najem długoterminowy i zajmuje
                    się ich przygotowaniem
                  </li>
                </ul>
              </StyledText> */}
            </ContentTextRight>
          </ContentText>
          <IframeContainer>
            <iframe
              width="560"
              height="315"
              src={data.datoCmsEarnWithMe.secondAccordionLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </IframeContainer>
        </Content>
      </AccordionWrapper>
      <AccordionWrapper>
        <TopTitle onClick={() => toggle(3)}>
          <TopHeadline>
            {data.datoCmsEarnWithMe.thirdAccordionTitle}
          </TopHeadline>
          <Arrow className={selected == 3 && "selected"}>
            <svg
              width="33"
              height="19"
              viewBox="0 0 33 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.1253 18.9536L32.999 16.0798L16.9253 0.00608063L0.851563 16.0798L3.72535 18.9536L16.9253 5.75366L30.1253 18.9536Z"
                fill="black"
              />
            </svg>
          </Arrow>
        </TopTitle>
        <Content className={selected == 3 && "show"}>
          <StyledText
            dangerouslySetInnerHTML={{
              __html: data.datoCmsEarnWithMe.thirdAccordionTextBefore,
            }}
          />
          {/* <StyledText>
            <strong>Wejdź do mieszkania pokazowego!</strong>
          </StyledText>
          <StyledText>
            Wejdź do jednego z mieszkań pokazowych i sam zweryfikuj jakość
            wykonanych usług!
          </StyledText> */}
          <IframeContainer>
            <iframe
              width="560"
              height="315"
              src={data.datoCmsEarnWithMe.thirdAccordionLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </IframeContainer>
          <StyledText
            dangerouslySetInnerHTML={{
              __html: data.datoCmsEarnWithMe.thirdAccordionTextAfter,
            }}
          />
          {/* <StyledText>
            <strong>Zapraszamy do oglądania!</strong>
          </StyledText>
          <StyledText>
            Po wcześniejszym ustaleniu daty i godziny można wejść do mieszkania
            i samodzielnie ocenić wysoką jakość usług.
          </StyledText> */}
        </Content>
      </AccordionWrapper>
    </Wrapper>
  );
};

export default Accordion;
